import { render, staticRenderFns } from "./special-use-label.vue?vue&type=template&id=266236ad&scoped=true&"
import script from "./special-use-label.vue?vue&type=script&lang=ts&"
export * from "./special-use-label.vue?vue&type=script&lang=ts&"
import style0 from "./special-use-label.vue?vue&type=style&index=0&id=266236ad&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266236ad",
  null
  
)

export default component.exports