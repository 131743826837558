import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { TagType } from '../../../typings/organization/label-manage';
import { namespace } from 'vuex-class';
const OrganizationModule = namespace('Organization');
let LabelManage = class LabelManage extends Vue {
    constructor() {
        super(...arguments);
        this.labelDetail = {};
        this.isShowEmpty = false;
        this.emptyData = {};
        this.TagType = TagType;
    }
    /**
     * 选中节点
     */
    async selectNode(curDeptDetail) {
        if (!curDeptDetail)
            return;
        if (curDeptDetail.labelType !== TagType.Label &&
            !curDeptDetail.isHasTag) {
            this.isShowEmpty = true;
            this.emptyData = curDeptDetail;
            return;
        }
        if (curDeptDetail.labelType !== TagType.Label && curDeptDetail.isHasTag) {
            return;
        }
        this.isShowEmpty = false;
        this.labelDetail = curDeptDetail;
        await this.$nextTick();
        if (this.labelInfo) {
            this.labelInfo.setDynamicTableHeight();
        }
    }
};
__decorate([
    Ref()
], LabelManage.prototype, "labelLeft", void 0);
__decorate([
    Ref()
], LabelManage.prototype, "labelInfo", void 0);
__decorate([
    OrganizationModule.State('enableOrgNum')
], LabelManage.prototype, "enableOrgNum", void 0);
LabelManage = __decorate([
    Component({
        name: 'LabelManage',
        components: {
            labelLeft: () => import('@/components/organization/label-manage/label-tree/index.vue'),
            labelInfo: () => import('@/components/organization/label-manage/label-info.vue'),
            labelEmpty: () => import('@/components/organization/label-manage/label-empty.vue'),
            NoEnableOrgPage: () => import('@/components/organization/org-manage/no-enable-org-page.vue'),
        },
    })
], LabelManage);
export default LabelManage;
